export type DynamicConfig = {
    [key: string]: string;
    environment: 'DEV' | 'PROD';
};

export const defaultConfig: DynamicConfig = {
    REACT_APP_MONETA_API: 'https://demo.moneta.ru/assistant.htm',
    REACT_APP_DS_REGISTRATION: 'https://passport-test.moex.com/registration',
    REACT_APP_ALGOPACK_API_DESC: 'https://www.moex.com/a2193',
    REACT_APP_MOEXALGO_GITHUB: 'https://github.com/moexalgo/moexalgo',
    REACT_APP_MOEXALGO_EXAMPLES: 'https://github.com/moexalgo/moexalgo/blob/main/samples/quick_start.ipynb',
    REACT_APP_MOEXALGO_DETAILS: 'https://github.com/WISEPLAT/backtrader_moexalgo/',
    REACT_APP_MOEXALGO_TELEGRAM: 'https://t.me/moex_algopack',
    REACT_APP_INVESTORPRO_DYNAMIC_TITLES:
        'опытных инвесторов&управляющих&финансовых консультантов&советников&всех, кто занимается долгосрочными инвестициями',
    REACT_APP_INVESTORPRO_TELEGRAM_LINK: 'https://t.me/MOEX_DATASHOP',
    REACT_APP_MOEXDATA_LINK: 'https://test02.datashop.moex.pcc.innoconsulting.ru:2/products/moexdata/',
    REACT_APP_TRADE_RADAR: 'https://trade-radar.ru/',
    REACT_APP_FOOTER_VK_LINK: 'https://vk.com/club226048516',
    REACT_APP_FOOTER_TELEGRAM_LINK: 'https://t.me/MOEX_DATASHOP',
    REACT_APP_INVESTOR_PRO_LINK: 'https://ipro.test02.datashop.moex.pcc.innoconsulting.ru:33/',
    REACT_APP_DS_URL: 'http://localhost:3000',
    environment: 'DEV',
};

export const dynamicConfigUrl = 'config.json';
