import { PaymentItem } from '@modules/CartPage/components/ProductsList/components/Payment/CardItemTemplate';
import {ProductReq} from '@libs/types';

export const additionalPaymentMethods: PaymentItem[] = [
    { paymentSystem: 'NEW_CARD', name: 'Новая карта', withoutCard: true },
    { paymentSystem: 'QR', name: 'СБП', withoutCard: true },
];

export const limitIds: { [key: string]: number } = {
    NEW_CARD: 43674,
    QR: 70019,
};

export const paymentSystems = ['VISA', 'MASTER_CARD', 'MAESTRO', 'MIR', 'UNION_PAY', 'UNKNOWN'];

export const productBodyReq: ProductReq = {
    pageable: {
        pageSize: 10,
        pageNumber: 0,
        sortField: 'order',
        sortOrder: 'asc',
    },
};
