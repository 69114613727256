import { classNames } from 'primereact/utils';
import { DescriptionBlock } from '@modules/ProPack/shared/components/DescriptionBlock';
import { useRef } from 'react';
import { Block } from '@modules/ProPack/shared/components/Block';
import { RiskRatesBlock } from '@modules/ProPackLanding/components/TariffBlock/RiskRatesBlock';
import { GainAccess } from '@libs/components';
import { type RefObjectType } from '@libs/types';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';

import { documentationBlocks } from './data';
import styles from './styles.module.scss';

export const RiskRates = () => {
    const gainAccessRef = useRef<HTMLDivElement>(null);
    const tariffsRef = useRef<HTMLDivElement>(null);

    const refs: RefObjectType<HTMLDivElement> = {};
    const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
        Object.assign(refs, childRefs);
    };

    const productGuids = useSelector((state: RootState) => state.product.productGuids);

    return (
        <div className={classNames(styles.pageWrapper)}>
            <DescriptionBlock
                title="Индикативные ставки риска"
                list={[
                    'Ставки риска роста',
                    'Ставки риска падения',
                    'Относительные ставки риска (ставки для множества с одним и тем же базовым активом)',
                ]}
                getAccessLink={gainAccessRef}
                tariffsLink={tariffsRef}
                className={styles.description}
            />

            <div className={styles.info}>
                <h1>Индикативные ставки риска, рассчитываемые НКО НКЦ (АО)</h1>
                <div className={styles.info_block}>
                    <p>
                        Данные ставки риска рассчитываются на горизонте риска, равном двум торговым дням, с
                        доверительной вероятностью не менее 99% по инструментам, допущенным к организованным торгам ПАО
                        Московская Биржа. Данные предоставляются в машиночитаемом формате через API Московской биржи —
                        ИСС.
                    </p>
                    <p>Индикативные ставки риска могут применяться брокерскими компаниями для расчета:</p>
                    <ul>
                        <li>
                            Начальной маржи клиентов в соответствии с Указанием Банка России от 26 ноября 2020 г. N
                            5636-У «О требованиях к осуществлению брокерской деятельности при совершении брокером
                            отдельных сделок за счет клиента»
                        </li>
                        <li>
                            ПКЛ (показатель краткосрочной ликвидности) в соответствии с Указанием Банка России от 6 июня
                            2017 г. N 4402-У «О требованиях к осуществлению брокерской деятельности в части расчета
                            показателя краткосрочной ликвидности при предоставлении клиентами брокера права
                            использования их денежных средств в его интересах»
                        </li>
                        <li>
                            ПДК (показатель достаточности капитала) в соответствии с Указанием Банка России от 30 ноября
                            2017 г. N 4630-У «О требованиях к осуществлению дилерской, брокерской деятельности,
                            деятельности по управлению ценными бумагами и деятельности форекс-дилеров в части расчета
                            показателя достаточности капитала»
                        </li>
                    </ul>
                </div>
            </div>

            <div className={styles.documentation}>
                <div className={styles.documentation_text}>
                    <h1>Документация</h1>
                    <span>
                        Вопросы использования информации: <span className={styles.link}>itsales@moex.com</span>
                    </span>
                </div>

                <div className={styles.blocks}>
                    {documentationBlocks.map((block, index) => (
                        <div key={index} className={styles.block}>
                            <Block
                                title={block.title}
                                note={block.note}
                                descriptionURL={block?.descriptionURL}
                                exampleURL={block?.exampleURL}
                                downloadURL={block?.downloadURL}
                                downloadText={block?.downloadText}
                                target={block?.target}
                                links={block?.links}
                                className={styles.blockSize}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.tariffs} ref={tariffsRef}>
                <h1>ТАРИФЫ</h1>
                <div className={styles.riskRates}>
                    <h2>Индикативные ставки риска</h2>
                    <div className={styles.price}>
                        <span>Ежемесячно без учета НДС</span>
                        <span>
                            от <span className={styles.cost}>6 000 ₽</span>
                        </span>
                    </div>
                    <span>
                        Узнать подробнее: <span className={styles.link}>itsales@moex.com</span>
                    </span>
                </div>
            </div>

            <div ref={gainAccessRef}>
                <GainAccess
                    setRefs={setChildRefs}
                    telephoneNumber={'+7 (495) 363-32-32 доб. 5656'}
                    productGuid={productGuids[ProductMnemoCodes.COMPLIENCE_TOOL]}
                    className={styles.gainAccess}
                />
            </div>
        </div>
    );
};
