import { classNames } from 'primereact/utils';
import { useEffect, useRef, useState } from 'react';
import { DescriptionBlock } from '@modules/ProPack/shared/components/DescriptionBlock';
import { LineItem } from '@modules/ProPack/shared/components/LineItem';
import { Block } from '@modules/ProPack/shared/components/Block';
import { GainAccess } from '@libs/components';
import { type RefObjectType } from '@libs/types';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';
import { TariffItem } from '@modules/ProPackLanding/components/TariffBlock/TariffItem';

import { blocks, items } from './data';
import styles from './styles.module.scss';

export const Markers = () => {
    const [openIndex, setOpenIndex] = useState<number | null>(0);
    const [showAllBlocks, setShowAllBlocks] = useState<boolean>(false);
    const [itemsToShow, setItemsToShow] = useState(4);

    useEffect(() => {
        const width = window.innerWidth;
        setItemsToShow(width > 360 ? 6 : 4);
    }, []);

    const handleItemClick = (index: number) => {
        if (index !== openIndex) {
            setOpenIndex(index);
        }
    };

    const handleShowAllBlocks = () => {
        setShowAllBlocks(true);
    };

    const visibleBlocks = showAllBlocks ? blocks : blocks.slice(0, itemsToShow);

    const refs: RefObjectType<HTMLDivElement> = {};
    const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
        Object.assign(refs, childRefs);
    };

    const productGuids = useSelector((state: RootState) => state.product.productGuids);

    const gainAccessRef = useRef<HTMLDivElement>(null);
    const tariffsRef = useRef<HTMLDivElement>(null);

    return (
        <div className={classNames(styles.pageWrapper)}>
            <DescriptionBlock
                title="Маркировка финансовых инструментов"
                text="Сервис позволяет участникам торгов автоматизировать ограничение предоставления доступа к финансовым инструментам для своих клиентов в соответствии с требованиями и рекомендациями регулятора. Сервис разработан в партнерстве с группой «Интерфакс»"
                getAccessLink={gainAccessRef}
                tariffsLink={tariffsRef}
                className={styles.description}
            />

            <div className={styles.items}>
                {items.map((item, index) => (
                    <LineItem
                        key={index}
                        item={item}
                        isOpen={index === openIndex}
                        onClick={() => handleItemClick(index)}
                    />
                ))}
            </div>

            <div className={styles.formats}>
                <div className={styles.formats_left}>
                    <h1 className={styles.formats_title}>Форматы доступа</h1>
                    <div className={styles.texts}>
                        <p>
                            Все данные по маркировке предоставляются с сервера ИСС Московской Биржи по API. Маркировка
                            финансовых инструментов по 39-ФЗ и Базовому стандарту доступна в ТКС.
                        </p>
                        <p>
                            Данные по маркировке можно получить в любом из 4 стандартных форматов: html, xml, json, csv
                        </p>
                    </div>
                    <div className={styles.links}>
                        <a href="https://fs.moex.com/files/6523">Руководство для разработчиков</a>
                        <a href="https://fs.moex.com/f/18973/marking-codes-25102023.xlsx">Коды значений маркировок</a>
                        <a href="https://iss.moex.com/iss/reference/" target="_blank" rel="noopener noreferrer">
                            Описание методов ИСС
                        </a>
                        <a href="https://fs.moex.com/files/6524">Примеры для разработчиков</a>
                    </div>
                    <div className={styles.info}>
                        <p>
                            Вопросы использования информации: <span className={styles.link}>itsales@moex.com</span>
                        </p>
                        <p>
                            Технические вопросы: <span className={styles.link}>help@moex.com</span>
                        </p>
                    </div>
                </div>

                <div className={styles.blocks}>
                    {visibleBlocks.map((block, index) => (
                        <div key={index} className={styles.block}>
                            <Block
                                update={block.update}
                                text={block.text}
                                accessURL={block.accessURL}
                                exampleURL={block.exampleURL}
                            />
                        </div>
                    ))}
                    {!showAllBlocks && (
                        <button className={styles.allBlocksButton} onClick={handleShowAllBlocks}>
                            Показать все
                        </button>
                    )}
                </div>
            </div>

            <div className={styles.tariffs} ref={tariffsRef}>
                <h1>ТАРИФЫ</h1>
                <div className={styles.markings}>
                    <h2>Маркировки финансовых инструментов</h2>
                    <TariffItem title={'Плата за регистрацию (единоразовая)'} price={'40 000'} points={[]} />
                    <span className={styles.extreme}>
                        <h3>Ежедневно обновляемые данные</h3>
                        <span>
                            Ежемесячно <br />
                            *без учета НДС
                        </span>
                        <p>Ежемесячно без учета НДС</p>
                    </span>
                    <TariffItem title={'Абонентская плата (ежемесячная)'} price={'от 15 000'} points={[]} />
                    <h2>Архивные данные в рамках Публичной оферты</h2>
                    <TariffItem title={'Плата (единоразовая) за 1 месяц архива'} price={'от 15 000'} points={[]} />
                </div>
            </div>

            <div ref={gainAccessRef}>
                <GainAccess
                    setRefs={setChildRefs}
                    telephoneNumber={'+7 (495) 363-32-32 доб. 5656'}
                    productGuid={productGuids[ProductMnemoCodes.COMPLIENCE_TOOL]}
                    className={styles.gainAccess}
                />
            </div>
        </div>
    );
};
