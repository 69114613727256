import React from 'react';
import { Footer, Navigation } from '@libs/components';

import { SearchPage } from './components';

export function SearchMainPage() {
    return (
        <>
            <Navigation />
            <SearchPage />
            <Footer />
        </>
    );
}
