import classNames from 'classnames';
import { ReactComponent as LinkBlue } from '@shared/images/svg/LinkBlue.svg';
import { ReactComponent as Example } from '@shared/images/svg/Example.svg';
import { ReactComponent as Info } from '@shared/images/svg/Info.svg';
import { ReactComponent as Save } from '@shared/images/svg/Save.svg';

import styles from './styles.module.scss';

type BlockProps = {
    update?: string;
    text?: string;
    title?: string;
    note?: string;
    accessURL?: string;
    exampleURL?: string;
    downloadURL?: string;
    descriptionURL?: string;
    downloadText?: string;
    className?: string;
    target?: string;
    links?: Array<{ text: string; link: string }>;
};

export const Block = ({
    update,
    text,
    title,
    note,
    accessURL,
    exampleURL,
    downloadURL,
    downloadText,
    descriptionURL,
    className,
    target,
    links,
}: BlockProps) => {
    return (
        <div className={classNames(styles.block, className)}>
            <div className={styles.texts}>
                {update && <span className={styles.update}>{update}</span>}
                {text && <span className={styles.text}>{text}</span>}
                {title && <span className={styles.title}>{title}</span>}
                {note && <span className={styles.note}>{note}</span>}
                {links && (
                    <div className={styles.downloads}>
                        {links.map((link, index) => (
                            <a
key={index} href={link.link} download
target={target} rel="noopener noreferrer">
                                {link.text}
                            </a>
                        ))}
                    </div>
                )}
            </div>

            <span className={styles.links}>
                {descriptionURL && (
                    <a href={descriptionURL} download>
                        <Info />
                        Описание полей
                    </a>
                )}
                {accessURL && (
                    <a href={accessURL} download>
                        <LinkBlue />
                        URL для доступа
                    </a>
                )}
                {exampleURL && (
                    <a href={exampleURL} download>
                        <Example />
                        Пример данных
                    </a>
                )}
                {downloadURL && (
                    <a
href={downloadURL} className={styles.specialA} target={target}
rel="noopener noreferrer">
                        <Save />
                        {downloadText}
                    </a>
                )}
            </span>
        </div>
    );
};
