import { Header } from '@modules/CKI/components/Header';
import { Footer, GainAccess, Navigation } from '@libs/components';
import type { RefObjectType } from '@libs/types';
import { DescriptionBlock } from '@modules/CKI/components/DescriptionBlock';
import { ProductCapabilities } from '@modules/CKI/components/ProductCapabilities';
import { useSelector } from 'react-redux';
import type { RootState } from '@store/store';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';

export const CKI = () => {
    const refs: RefObjectType<HTMLDivElement> = {};

    const productGuids = useSelector((state: RootState) => state.product.productGuids);
    const isProductLoading = useSelector((state: RootState) => state.product.isLoading);

    const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
        Object.assign(refs, childRefs);
    };

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <Header refs={refs} />
                    <DescriptionBlock />
                    <ProductCapabilities />
                    {!isProductLoading && (
                        <GainAccess setRefs={setChildRefs} productGuid={productGuids[ProductMnemoCodes.CKI]} />
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};
