import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { createOrderThunk } from '@store/store/thunk/order.thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { getSavedCardsThunk } from '@store/store/thunk/card.thunk';
import { Select } from '@modules/CartPage/components/ProductsList/components/ProductItem/components/Select';
import {
    CardItemTemplate,
    type PaymentItem,
} from '@modules/CartPage/components/ProductsList/components/Payment/CardItemTemplate';
import { type SavedCard, type NewCardData, type OrderReq } from '@libs/types';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '@dynamic-env/useConfig';

import styles from './style.module.scss';

const additionalPaymentMethods: PaymentItem[] = [
    { paymentSystem: 'NEW_CARD', name: 'Новая карта', withoutCard: true },
    { paymentSystem: 'SBP', name: 'СБП', withoutCard: true },
];

const paymentSystems = ['VISA', 'MASTER_CARD', 'MAESTRO', 'MIR', 'UNION_PAY', 'UNKNOWN'];

const limitIds: Record<string, number> = {
    NEW_CARD: 43674,
    SBP: 70019,
};

export const Payment = () => {
    const { config } = useConfig();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const savedCards = useSelector((state: RootState) => state.card.savedCards);
    const paymentOptions = [...savedCards, ...additionalPaymentMethods];
    const [selectedPayment, setSelectedPayment] = useState<PaymentItem | SavedCard>(paymentOptions[0]);

    useEffect(() => {
        dispatch(getSavedCardsThunk());
    }, []);

    useEffect(() => {
        const defaultCard = savedCards.find((item) => item.isDefault);

        if (defaultCard) {
            setSelectedPayment(defaultCard);
        }
    }, [savedCards]);

    const paymentHandler = () => {
        const body: OrderReq = {
            paymentMethod: paymentSystems.includes(selectedPayment.paymentSystem)
                ? 'SAVED_CARD'
                : selectedPayment.paymentSystem || 'NEW_CARD',
        };

        if (paymentSystems.includes(selectedPayment.paymentSystem) && 'id' in selectedPayment) {
            body.cardId = selectedPayment.id;
        }

        dispatch(createOrderThunk(body))
            .then((resp) => {
                const result = unwrapResult(resp);

                if (result) {
                    if (['NEW_CARD', 'SBP'].includes(result.paymentMethod)) {
                        const { accountId, operationId, amount, transactionId, signature } = result.data as NewCardData;
                        window.location.href = `${config.REACT_APP_MONETA_API}?MNT_ID=${accountId}&operationId=${operationId}&MNT_AMOUNT=${amount}&MNT_CURRENCY_CODE=RUB&MNT_TRANSACTION_ID=${transactionId}&MNT_SIGNATURE=${signature}&paymentSystem.limitIds=${limitIds[result.paymentMethod]}&MNT_SUCCESS_URL=${config.REACT_APP_DS_URL}/payment-info/success&MNT_FAIL_URL=${config.REACT_APP_DS_URL}/payment-info/error`;
                    } else if (result.paymentMethod === 'SAVED_CARD') {
                        const { transactionId } = result.data;
                        navigate(`/payment-info/success?MNT_TRANSACTION_ID=${transactionId}`);
                    }
                }
            })
            .catch(() => {
                navigate('/payment-info/error');
            });
    };

    return (
        <div className={styles.paymentContainer}>
            <div className={styles.cardsSection}>
                <div>Способ оплаты</div>
                <div className={styles.paymentSelect}>
                    <Select
                        options={paymentOptions}
                        value={selectedPayment}
                        valueTemplate={CardItemTemplate}
                        itemTemplate={CardItemTemplate}
                        onChangeHandler={setSelectedPayment}
                        optionLabel={'cardNumber'}
                    />
                </div>
            </div>
            <div className={styles.buttonSection}>
                <Button
                    className={styles.button}
                    label="Оплатить"
                    disabled={'name' in selectedPayment && selectedPayment.name !== 'Новая карта'}
                    onClick={paymentHandler}
                />
            </div>
        </div>
    );
};
