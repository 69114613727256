import { Footer, GainAccess, Navigation } from '@libs/components';
import { LogoBlock } from '@modules/ProPackLanding/components/LogoBlock';
import React, { useEffect } from 'react';
import type { RefObjectType } from '@libs/types';
import { NavigationBlock } from '@modules/ProPackLanding/components/NavigationBlock';
import { DescriptionServices } from '@modules/ProPackLanding/components/DescriptionServices';
import { InterestRates } from '@modules/ProPackLanding/components/InterestRates';
import { Volatility } from '@modules/ProPackLanding/components/Volatility';
import { RiskRates } from '@modules/ProPackLanding/components/RiskRates';
import { MarkingFinInstruments } from '@modules/ProPackLanding/components/MarkingFinInstruments';
import { DuplicateIIS } from '@modules/ProPackLanding/components/DuplicatesIIS';
import { TrialVersion } from '@modules/ProPackLanding/components/TrialVersion';
import { TariffBlock } from '@modules/ProPackLanding/components/TariffBlock';
import { addMetaTag, removeMetaTag } from '@libs/utils';
import { useSelector } from 'react-redux';
import type { RootState } from '@store/store';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';

export const ProPackLanding = () => {
    const refs: RefObjectType<HTMLDivElement> = {};

    const productGuids = useSelector((state: RootState) => state.product.productGuids);
    const isProductLoading = useSelector((state: RootState) => state.product.isLoading);

    const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
        Object.assign(refs, childRefs);
    };

    useEffect(() => {
        addMetaTag('title', 'Pro Pack - сервис глубокой биржевой аналитики и работы с данными');
        addMetaTag(
            'description',
            'Всесторонний анализ торговли на Московской бирже и профессиональная аналитика биржевых рынков от сервиса Pro Pack. Платформа предоставляет актуальные данные и инсайты, помогая трейдерам и инвесторам принимать решения на основе анализа динамики рынка.',
        );

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
        };
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <LogoBlock refs={refs} />
                    <NavigationBlock refs={refs} />
                    <DescriptionServices refs={refs} />
                    <InterestRates refs={refs} setRefs={setChildRefs} />
                    <Volatility refs={refs} setRefs={setChildRefs} />
                    <RiskRates refs={refs} setRefs={setChildRefs} />
                    <MarkingFinInstruments refs={refs} setRefs={setChildRefs} />
                    <DuplicateIIS refs={refs} setRefs={setChildRefs} />
                    <TrialVersion />
                    {!isProductLoading && (
                        <GainAccess setRefs={setChildRefs} productGuid={productGuids[ProductMnemoCodes.TPC_PRO_PACK]} />
                    )}
                    <TariffBlock setRefs={setChildRefs} />
                </div>
            </div>
            <Footer />
        </>
    );
};
