import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { ReactComponent as SuccessIcon } from '@shared/images/svg/success.svg';
import { ReactComponent as ErrorIcon } from '@shared/images/svg/error.svg';

import styles from './styles.module.scss';

export const Content = () => {
    const [searchParams] = useSearchParams();
    const params = useParams();
    const navigate = useNavigate();

    const successBlock = (
        <>
            <SuccessIcon />
            <h2>Платеж за заказ #{searchParams.get('MNT_TRANSACTION_ID')} принят</h2>
            <p>
                Кассовый чек будет отправлен Вам на электронную почту. Информацию по доступным услугам смотрите в личном
                кабинете.
            </p>
        </>
    );

    const errorBlock = (
        <>
            <ErrorIcon />
            <h2>Ошибка оплаты</h2>
            <p>
                Произошла ошибка при обработке вашего платежа. Пожалуйста, попробуйте еще раз или обратитесь в службу
                поддержки.
            </p>
        </>
    );

    return (
        <div
            className={classNames(
                styles.paymentInfoWrapper,
                'flex',
                'flex-column',
                'justify-content-center',
                'align-items-center',
            )}
        >
            {params.status === 'success' ? successBlock : errorBlock}
            <div className={styles.buttonWrapper}>
                <Button
                    label={'Вернуться в DataShop'}
                    onClick={() => navigate(params.status === 'success' ? '/personal-account' : '/')}
                />
            </div>
        </div>
    );
};
