import React, { useEffect, useState } from 'react';
import { Feedback, Footer, Navigation } from '@libs/components';
import { useParams } from 'react-router-dom';
import { type Reference } from '@modules/HelpCenter/HelpCenterPage/services/helpCenter.types';
import { getReference } from '@modules/HelpCenter/HelpCenterPage/services/helpCenter.service';
import { type Article } from '@libs/types/articles/article.types';
import { getArticle } from '@libs/services/article.service';
import { addMetaTag, removeMetaTag } from '@libs/utils';

import { TopBlock } from './components/TopBlock';
import { MainContent } from './components/MainContent';

export const ReferencePage = () => {
    const { referenceId } = useParams<{ referenceId: string }>();
    const { articleId } = useParams<{ articleId: string }>();
    const [reference, setReference] = useState<Reference>();
    const [article, setArticle] = useState<Article>();

    useEffect(() => {
        if (referenceId) {
            getReference(referenceId)
                .then((response) => {
                    const referenceRes = response.data;
                    setReference(referenceRes);

                    const articleIdDefault = referenceRes.articles.length
                        ? `${referenceRes.articles[0].article_id}`
                        : null;

                    if (articleId || articleIdDefault) {
                        getArticleData(articleId || articleIdDefault || '');
                    }

                    const { title } = referenceRes;
                    addMetaTag('title', `Информация о сервисе ${title} - | Помощь с инвестициями от Datashop`);
                    addMetaTag(
                        'description',
                        `Полезная информация о сервисе ${title} - надежном ресурсе для инвестиционного и финансового анализа. ${title} может стать вашим ключом к успешным инвестициям, рассказывая о последних тенденциях и предоставляя доступ к актуальным данным рынка.`,
                    );
                })
                .catch((error) => {
                    console.error(error.message);
                });
        }

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
        };
    }, [referenceId]);

    useEffect(() => {
        if (articleId) {
            getArticleData(articleId);
        } else {
            setArticle(undefined);
        }
    }, [articleId]);

    const getArticleData = (articleId: string) => {
        getArticle(articleId)
            .then((response) => {
                setArticle(response.data);
            })
            .catch((error) => {
                console.error(error.message);
            });
    };

    return (
        <>
            <Navigation />
            <TopBlock title={reference?.title || ''} />
            <MainContent reference={reference} article={article} />
            <Feedback />
            <Footer />
        </>
    );
};
