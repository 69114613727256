import { ReactComponent as TelegramIcon } from '@shared/images/svg/Telegram.svg';
import { ReactComponent as VkIcon } from '@shared/images/svg/Vk.svg';
import { ReactComponent as Support } from '@shared/images/svg/Support.svg';
import { ReactComponent as FooterLogoIcon } from '@shared/images/svg/FooterLogo.svg';
import classNames from 'classnames';
import { useConfig } from '@dynamic-env/useConfig';

import styles from './styles.module.scss';

export const Footer = () => {
    const { config } = useConfig();

    return (
        <section className={styles.footerAdititonalContainer}>
            <div className={styles.footerWrapper}>
                <div className={styles.footerHeader}>
                    <div className={styles.headerInfo}>
                        <a target={'_blank'} rel="noreferrer" href="https://www.moex.com/s249">
                            <p>Раскрытие информации</p>
                        </a>
                        <a target={'_blank'} rel="noreferrer" href={'/docs/Пользовательское соглашение.pdf'}>
                            <p>Пользовательское соглашение</p>
                        </a>
                        <a
                            target={'_blank'}
                            rel="noreferrer"
                            href="https://finuslugi.ru/disclosure/politika_konfidencialnosti?_gl=1*16lrmb2*_ga*NTMwMDk1ODE1LjE3MTYyMTEwNzU.*_ga_Z0GRHZ4BLP*MTcxNjU0NDY1Ny42LjAuMTcxNjU0NDY2NC41My4wLjA"
                        >
                            <p>Политика обработки персональных данных</p>
                        </a>
                    </div>
                </div>

                <div className={styles.logoWrapper}>
                    <FooterLogoIcon />
                </div>

                <div className={styles.infoWrapper}>
                    <p>MOEX DATASHOP, 2024 г. — сервис ПАО Московская биржа.</p>
                    <p>
                        Все права на информацию и аналитические материалы, размещенные на настоящем сайте Биржи,
                        защищены в соответствии с российским законодательством. Прежде чем приступить к использованию
                        сайта предлагаем ознакомиться с Пользовательским соглашением. Воспроизведение, распространение и
                        иное использование информации, размещенной на сайте Биржи, или ее части допускается только с
                        предварительного письменного согласия Биржи.
                    </p>
                </div>
                <div className={styles.socialWrapper}>
                    <a
                        className={classNames(styles.socialWrapper_icon, styles.socialWrapper_icon__centered)}
                        href={config.REACT_APP_FOOTER_TELEGRAM_LINK}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                    >
                        <TelegramIcon />
                    </a>
                    <a
                        className={styles.socialWrapper_icon}
                        href={config.REACT_APP_FOOTER_VK_LINK}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                    >
                        <VkIcon />
                    </a>
                </div>
                <div className={styles.supportWrapper}>
                    <div className={styles.support_icon}>
                        <Support />
                    </div>
                    <div>
                        <p className={styles.support_call}>
                            Поддержка: <span>8 800 533-88-37</span>
                        </p>
                        <p className={styles.support_schedule}>Будни: с 7:00 до 23:00 мск</p>
                    </div>
                </div>
            </div>
        </section>
    );
};
