import { type TariffFilterItem } from '@libs/types';

export const AllTariffPeriods: TariffFilterItem[] = [
    { code: 'month', title: 'на месяц', orderValue: 1 },
    { code: 'month3', title: 'на 3 месяца', orderValue: 2 },
    { code: 'month6', title: 'на 6 месяцев', orderValue: 3 },
    { code: 'year', title: 'на год', orderValue: 4 },
];

export const initialTariffs = {
    month: [],
    month3: [],
    month6: [],
    year: [],
};
