import { useEffect } from 'react';
import { MarketDiveMain } from '@modules/MarketDive/components';
import { Footer, Navigation } from '@libs/components';
import { addMetaTag, removeMetaTag } from '@libs/utils';

export const MarketDive = () => {
    useEffect(() => {
        addMetaTag('title', 'Market Dive — источник биржевой информации и данных для эффективной торговли');
        addMetaTag(
            'description',
            'Сервис Market Dive от Московской биржи предоставляет доступ к актуальной биржевой информации, глубокому анализу данных и отчетам на их основе. Исследуйте рыночные тенденции с нами для разработки эффективной стратегии.',
        );

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
        };
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <MarketDiveMain />
                </div>
            </div>
            <Footer />
        </>
    );
};
