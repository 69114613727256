import classNames from 'classnames';
import { type ReactNode } from 'react';
import { ReactComponent as MasterCard } from '@shared/images/svg/cardIcons/MasterCard.svg';
import { ReactComponent as Mir } from '@shared/images/svg/cardIcons/Mir.svg';
import { ReactComponent as UnionPay } from '@shared/images/svg/cardIcons/UnionPay.svg';
import { ReactComponent as Visa } from '@shared/images/svg/cardIcons/Visa.svg';
import { ReactComponent as Maestro } from '@shared/images/svg/cardIcons/Maestro.svg';
import { ReactComponent as NewCard } from '@shared/images/svg/cardIcons/NewCard.svg';
import { ReactComponent as SBP } from '@shared/images/svg/cardIcons/SBP.svg';
import { type SavedCard } from '@libs/types';

import styles from './style.module.scss';

const icons: Record<string, ReactNode> = {
    MASTER_CARD: <MasterCard />,
    MIR: <Mir />,
    UNION_PAY: <UnionPay />,
    VISA: <Visa />,
    MAESTRO: <Maestro />,
    NEW_CARD: <NewCard />,
    QR: <SBP />,
    UNKNOWN: <NewCard />,
};

const paymentSystemName: Record<string, string> = {
    VISA: 'Visa',
    MASTER_CARD: 'MasterCard',
    MAESTRO: 'Maestro',
    MIR: 'Mir',
    UNION_PAY: 'UnionPay',
    UNKNOWN: '',
};

export type PaymentItem = {
    paymentSystem: string;
    name: string;
    withoutCard: boolean;
};

export const CardItemTemplate = (props: SavedCard | PaymentItem) => {
    return (
        <div className={classNames(styles.cardTemplate, 'flex', 'align-items-center')}>
            <div className={classNames(styles.cardIcon)}>{icons[props.paymentSystem]}</div>
            <div>
                <div className={styles.cardType}>
                    {!('withoutCard' in props) && paymentSystemName[props.paymentSystem]}
                </div>
                <div className={classNames(styles.cardNumber, 'flex', 'align-items-center')}>
                    <span className={classNames('withoutCard' in props && props.withoutCard && styles.withOutCard)}>
                        {('cardNumber' in props && props.cardNumber) || ('name' in props && props.name)}
                    </span>
                </div>
            </div>
        </div>
    );
};
