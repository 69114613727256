import { classNames } from 'primereact/utils';
import { DescriptionBlock } from '@modules/ProPack/shared/components/DescriptionBlock';
import { useRef, useState } from 'react';
import { Block } from '@modules/ProPack/shared/components/Block';
import { GainAccess } from '@libs/components';
import { type RefObjectType } from '@libs/types';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';
import { LineItem } from '@modules/ProPack/shared/components/LineItem';

import { documentationBlocks, items } from './data';
import { DuplicateIISBlock } from './DuplicateIISBlock';
import styles from './styles.module.scss';

export const IisDuplicates = () => {
    const [openIndex, setOpenIndex] = useState<number | null>(0);

    const gainAccessRef = useRef<HTMLDivElement>(null);
    const tariffsRef = useRef<HTMLDivElement>(null);

    const refs: RefObjectType<HTMLDivElement> = {};
    const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
        Object.assign(refs, childRefs);
    };

    const productGuids = useSelector((state: RootState) => state.product.productGuids);

    const handleItemClick = (index: number) => {
        if (index !== openIndex) {
            setOpenIndex(index);
        }
    };

    return (
        <div className={classNames(styles.pageWrapper)}>
            <DescriptionBlock
                title="Дубликаты ИИС"
                text="Онлайн сервис проверки Индивидуальных Инвестиционных Счетов физических лиц на задвоенность"
                getAccessLink={gainAccessRef}
                tariffsLink={tariffsRef}
                className={styles.description}
            />

            <div className={styles.items}>
                <h1>Использование сервиса ускоряет процесс открытия ИИС клиента у самого участника торгов</h1>
                {items.map((item, index) => (
                    <LineItem
                        key={index}
                        item={item}
                        isOpen={index === openIndex}
                        onClick={() => handleItemClick(index)}
                    />
                ))}
            </div>

            <div className={styles.documentation}>
                <div className={styles.documentation_left}>
                    <h1>Документация</h1>
                    <p className={styles.text}>
                        Услуга предоставляется только профессиональным участникам торгов при наличии сервиса Web API
                        Регистрации клиентов
                    </p>
                    <div className={styles.links}>
                        <a href="https://www.moex.com/a788" target="_blank" rel="noopener noreferrer">
                            Общая страница Регистрации клиентов
                        </a>
                        <a href="https://fs.moex.com/f/9945/client-reg-api.zip">
                            Описание сервиса Web API Регистрации клиентов
                        </a>
                        <a href="https://fs.moex.com/files/20918">
                            Презентация услуги «Сервис онлайн-проверки ИИС на задвоенность»
                        </a>
                    </div>
                    <p className={styles.info}>
                        Вопросы использования информации: <span className={styles.link}>itsales@moex.com</span>
                    </p>
                </div>

                <div className={styles.blocks}>
                    {documentationBlocks.map((block, index) => (
                        <div key={index} className={styles.block}>
                            <Block
                                update={block.update}
                                text={block.text}
                                downloadURL={block.downloadURL}
                                downloadText={block.downloadText}
                                target={block.target}
                                className={styles.blockSize}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.tariffs} ref={tariffsRef}>
                <h1>ТАРИФЫ</h1>
                <div className={styles.iisDuplicates}>
                    <h2>Дубликаты ИИС</h2>
                    <div className={styles.price}>
                        <span className="flex">
                            <h3>1 запрос</h3>
                            без учета НДС
                        </span>
                        <span>
                            от <span className={styles.cost}>20 ₽</span>
                        </span>
                    </div>
                    <span>
                        Узнать подробнее: <span className={styles.link}>itsales@moex.com</span>
                    </span>
                </div>
            </div>

            <div ref={gainAccessRef}>
                <GainAccess
                    setRefs={setChildRefs}
                    telephoneNumber={'+7 (495) 363-32-32 доб. 5656'}
                    productGuid={productGuids[ProductMnemoCodes.COMPLIENCE_TOOL]}
                    className={styles.gainAccess}
                />
            </div>
        </div>
    );
};
