import { useEffect, useRef, useState } from 'react';
import { type Nullable } from 'primereact/ts-helpers';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { showLoader, hideLoader } from '@store/store/slices/loading.slice';
import { endOfYesterday, format } from 'date-fns';
import { type DataTableValue } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { type ChartData } from 'chart.js';
import { type PlotData } from 'plotly.js';
import classNames from 'classnames';
import { formatDateStr } from '@modules/ProPack/components/YieldCurves/dataConfig';
import { TableWithCheckBox } from '@modules/ProPack/components/Volatility/components/TableWithCheckBox';

import {
    MAX_ACTIVES,
    MAX_CURVES_EXCEEDED_MSG,
    SCREEN_OPTIONS,
    SMILE_ACTIVES_COLORS,
    VOLAT_TABLE_TITLES,
} from './dataConfig';
import { ControlPanel } from './components/ControlPanel';
import { SmileChart } from './components/SmileChart';
import { SurfaceActivesTable } from './components/SurfaceActivesTable';
import { SurfaceChart } from './components/SurfaceChart';
import { getAssetGroupsAndTypes } from './services/getAssetGroupsAndTypes.service';
import { type GroupModelList, type AssetTypeModelList, type AssetModel, type RowForSelect } from './services/types';
import { getAssetList } from './services/getAssetList.service';
import { getSmileChartCoords } from './services/getSmileChartCoords.service';
import styles from './styles.module.scss';
import { getSurfaceChartCoords } from './services/getSurfaceChartCoords.service';

export const Volatility = () => {
    const [screen, setScreen] = useState(SCREEN_OPTIONS[0]);
    const [groupList, setGroupList] = useState<GroupModelList[]>([]);
    const [activesGroupsValue, setActivesGroupsValue] = useState<GroupModelList | undefined>(undefined);
    const [activesTypesValue, setActivesTypesValue] = useState<AssetTypeModelList | undefined>(undefined);
    const [surfaceChartDate, setSurfaceChartDate] = useState<Nullable<Date>>(endOfYesterday());
    const [smileChartDate, setSmileChartDate] = useState<Nullable<Date>>(endOfYesterday());
    const [tableData, setTableData] = useState<AssetModel[]>([]);

    const [selectedSmilesActives, setSelectedSmilesActives] = useState<RowForSelect[]>([]);
    const [smileColors, setSmileColors] = useState<string[]>([...SMILE_ACTIVES_COLORS]);
    const [selectedSurfaceActives, setSelectedSurfaceActives] = useState<Nullable<DataTableValue | null>>(null);

    const [surfaceData, setSurfaceData] = useState<Partial<PlotData | any>>([]);
    const [smileChartData, setSmileChartData] = useState<Partial<ChartData | any>>({ datasets: [] });
    const dispatch = useDispatch<AppDispatch>();

    const hasEnoughData = !!activesTypesValue && !!activesGroupsValue && !!smileChartDate && !!surfaceChartDate;
    const toast = useRef<Toast>(null);

    useEffect(() => {
        const getGroupList = async () => {
            dispatch(showLoader());
            try {
                const groupsAndTypesRequest = await getAssetGroupsAndTypes();
                setGroupList(groupsAndTypesRequest?.data.data.groupModelList);
            } catch (error) {
                console.log('error: ', error);
            }
            dispatch(hideLoader());
        };
        getGroupList();
    }, []);

    const getTableData = async (activesTypes: AssetTypeModelList | undefined, date: Nullable<Date>) => {
        if (activesTypes && date) {
            dispatch(showLoader());
            try {
                const assetListRequest = await getAssetList(activesTypes.assetTypeName, format(date, formatDateStr));
                const tableData = assetListRequest.data.data;
                // TODO: remove this after BE adds ids
                tableData.forEach((asset: any, index: number) => (asset.assetId = index));
                setTableData(tableData);
            } catch (error) {
                console.log('error: ', error);
            }
            dispatch(hideLoader());
        }
    };

    const resetChartsData = () => {
        setSmileChartData({ datasets: [] });
        setSurfaceData([]);
        setSelectedSmilesActives([]);
        setSelectedSurfaceActives(null);
        setSmileColors([...SMILE_ACTIVES_COLORS]);
    };

    const resetTableData = () => {
        setTableData([]);
    };

    const handleScreenChange = (screenName: string) => {
        setScreen(screenName);
        setActivesGroupsValue(undefined);
        setActivesTypesValue(undefined);
        resetChartsData();
        resetTableData();
    };
    const handleActivesGroupsChange = (groupName: string | undefined) => {
        resetChartsData();
        resetTableData();
        const newActiveGroup = groupList?.find((group: GroupModelList) => group.groupName === groupName);

        if (newActiveGroup) {
            setActivesGroupsValue(newActiveGroup);
        }
    };
    const handleActivesTypesChange = (typeName: string | undefined) => {
        resetChartsData();
        const newActiveType = activesGroupsValue?.assetTypeModelList.find(
            (type: AssetTypeModelList) => type.assetTypeName === typeName,
        );

        if (newActiveType) {
            setActivesTypesValue(newActiveType);
            getTableData(newActiveType, screen === SCREEN_OPTIONS[0] ? smileChartDate : surfaceChartDate);
        }
    };

    const handleSmileDateChange = (date: Nullable<Date>) => {
        if (date) {
            setSmileChartDate(date);
            resetChartsData();
            getTableData(activesTypesValue, date);
        }
    };

    const handleSurfaceDateChange = (date: Nullable<Date>) => {
        if (date) {
            setSurfaceChartDate(date);
            resetChartsData();
            getTableData(activesTypesValue, date);
        }
    };

    const handleSmileSelect = async (row: RowForSelect) => {
        const duplicateRow = selectedSmilesActives.find(({ assetId }) => assetId === row.assetId);

        if (duplicateRow) {
            setSelectedSmilesActives((prev) => prev.filter((sec) => sec !== duplicateRow));

            if (!row.color) {
                setSmileColors((prev: any) => [duplicateRow.color, ...prev]);
            }
            const newDatasets = smileChartData.datasets.filter((dataset: any) => dataset.id !== duplicateRow.assetId);
            setSmileChartData({ datasets: newDatasets });
        } else {
            if (selectedSmilesActives.length < MAX_ACTIVES) {
                dispatch(showLoader());
                setSelectedSmilesActives([
                    ...selectedSmilesActives,
                    {
                        ...row,
                        color: smileColors[0],
                    },
                ]);
                const smileReq = await getSmileChartCoords(
                    row.asset_type,
                    row.asset,
                    format(smileChartDate!, formatDateStr),
                    row.last_trade_date,
                );
                const newDataset = {
                    id: row.assetId,
                    label: `${row.asset_type} (${row.asset}, ${row.last_trade_date})`,
                    data: smileReq.data.data.data_curve_List,
                    borderColor: smileColors[0],
                    pointHoverBackgroundColor: smileColors[0],
                    date: row.last_trade_date,
                };
                setSmileChartData((prev: any) => ({ datasets: [...prev.datasets, newDataset] }));
                setSmileColors((prev: any) => prev.slice(1));
                dispatch(hideLoader());
            } else {
                toast.current?.show({
                    detail: MAX_CURVES_EXCEEDED_MSG,
                    life: 3000,
                    icon: 'pi pi-bell',
                    pt: {
                        root: () => ({ className: styles.toast }),
                        icon: () => ({ className: styles.toastIcon }),
                    },
                });
            }
        }
    };

    const handleSurfaceActiveSelect = async (asset: DataTableValue) => {
        dispatch(showLoader());
        setSelectedSurfaceActives(asset);
        const surfaceReq = await getSurfaceChartCoords(asset.asset_type, format(surfaceChartDate!, formatDateStr));
        setSurfaceData(surfaceReq.data.data as any);
        dispatch(hideLoader());
    };

    return (
        <div className={classNames(styles.pageWrapper)}>
            <ControlPanel
                selectedScreen={screen}
                groupList={groupList}
                handleScreenChange={handleScreenChange}
                handleActivesGroupsChange={handleActivesGroupsChange}
                handleActivesTypesChange={handleActivesTypesChange}
                activesGroupsValue={activesGroupsValue}
                activesTypesValue={activesTypesValue}
                surfaceChartDate={surfaceChartDate}
                setSurfaceChartDate={handleSurfaceDateChange}
                smileChartDate={smileChartDate}
                setSmileChartDate={handleSmileDateChange}
            />
            <div className={classNames(hasEnoughData ? styles.contentWrapper : styles.hidden)}>
                {screen === SCREEN_OPTIONS[0]
? (
                    <div>
                        <Toast ref={toast} position="bottom-center" />
                        <div className={classNames(styles.tableWrapper)}>
                            <TableWithCheckBox
                                data={tableData}
                                selected={selectedSmilesActives}
                                handleSelect={handleSmileSelect}
                                columns={VOLAT_TABLE_TITLES}
                            />
                        </div>
                        {tableData.length > 0 ? <SmileChart smileChartData={smileChartData} /> : null}
                    </div>
                )
: (
                    <div>
                        <div className={classNames(styles.tableWrapper)}>
                            <SurfaceActivesTable
                                data={tableData}
                                selectedActives={selectedSurfaceActives}
                                handleActivesChange={handleSurfaceActiveSelect}
                            />
                        </div>
                        <SurfaceChart surfaceData={surfaceData} />
                    </div>
                )}
            </div>
        </div>
    );
};
