import { useEffect, useState } from 'react';
import { type AppDispatch, type RootState } from '@store/store';
import { getDataUploadThunk } from '@store/store/thunk/pricecenter/getData.thunk';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PriceCentrePermissions } from '@libs/types/subscription.type';

import { getDateTransformed, getTradeDate } from '../InteractiveMap/utils';
import { ErrorMsg } from './components/ErrorMsg';
import { PeriodForm } from './components/PeriodForm';
import { InstrumentsForm } from './components/InstrumentsForm';
import { TableWrapper } from './components/TableWrapper';
import { instumentsColumns, peridoColumns } from './constants';
import { getRowsPrepared } from './utils';
import styles from './styles.module.scss';
import { PriceCenterProductTabs } from '../types';

type DataUploadProps = {
    permissions?: PriceCentrePermissions[];
};

export const DataUpload = ({ permissions }: DataUploadProps) => {
    const [_, setSearchParams] = useSearchParams();
    const [periodToggleActive, setPeriodToggleActive] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();
    const { data, error: reqDataError } = useSelector((state: RootState) => state.priceCenter.dataUpload);

    useEffect(() => {
        if (!permissions?.includes(PriceCentrePermissions.EXPORTS)) {
            setSearchParams({ tab: PriceCenterProductTabs.INTERACTIVE_MAP });
        }
    }, [permissions]);

    useEffect(() => {
        const tradeDate = getDateTransformed(getTradeDate())!;
        dispatch(getDataUploadThunk(tradeDate as unknown as Date));
    }, []);

    const formView = periodToggleActive
? (
        <PeriodForm bonds={data?.bonds} emitters={data?.emitters} />
    )
: (
        <InstrumentsForm bonds={data?.bonds} emitters={data?.emitters} />
    );

    return (
        <div className={styles.dataUploadContainer}>
            <h1>Выгрузка данных</h1>
            <div className={styles.dataUpload}>
                <div className={styles.toggleButtons}>
                    <Button
                        type="button"
                        label="Список инструментов на дату"
                        className={classNames(styles.toggleButton, styles.toggleLeft, {
                            [styles['toggleActive']]: !periodToggleActive,
                            [styles['toggleInactive']]: periodToggleActive,
                        })}
                        onClick={() => setPeriodToggleActive(false)}
                    />
                    <Button
                        type="button"
                        label="История по инструменту"
                        className={classNames(styles.toggleButton, styles.toggleRight, {
                            [styles['toggleActive']]: periodToggleActive,
                            [styles['toggleInactive']]: !periodToggleActive,
                        })}
                        onClick={() => setPeriodToggleActive(true)}
                    />
                </div>

                {formView}
            </div>

            {reqDataError === 'NO_DATA'
? (
                <ErrorMsg />
            )
: (
                <>
                    {data?.bondsSelected?.length > 0 && !periodToggleActive && (
                        <TableWrapper
                            type="instruments"
                            cols={instumentsColumns}
                            rows={getRowsPrepared(data?.bondsSelected)}
                        />
                    )}

                    {data?.periodSelected?.length > 0 && periodToggleActive && (
                        <TableWrapper type="period" cols={peridoColumns} rows={getRowsPrepared(data?.periodSelected)} />
                    )}
                </>
            )}
        </div>
    );
};
