import { type Dispatch, type SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { NotificationCard } from '@libs/components/pageBlocks/Navigation/Notifications/components/NotificationCard';
import {
    getExtraNotificationsThunk,
    getNotificationsThunk,
    getUnreadNotificationsCountThunk,
    setNotificationStatusThunk,
} from '@store/store/thunk/notification.thunk';
import { NotificationModal } from '@libs/components/pageBlocks/Navigation/Notifications/components/NotificationModal';
import { type Notification, type NotificationArgs } from '@libs/types';
import { EmptyNotifications } from '@libs/components/pageBlocks/Navigation/Notifications/components/EmptyNotifications';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useAuthorize } from '@libs/utils';
import {
    getReadNotificationFromLocalStorage,
    getUniqIds,
} from '@libs/components/pageBlocks/Navigation/Notifications/utils';
import { Sidebar } from 'primereact/sidebar';

import styles from './styles.module.scss';

const PAGE_SIZE = 10;

type NotificationsProps = {
    isOpen: boolean;
    setIsNotificationsOpen: Dispatch<SetStateAction<boolean>>;
};

export const Notifications = ({ isOpen, setIsNotificationsOpen }: NotificationsProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { isAuthorized } = useAuthorize();
    const contentRef = useRef<HTMLDivElement>(null);

    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [mainLoader, setMainLoader] = useState<boolean>(true);
    const [startGettingExtraNotifications, setStartGettingExtraNotifications] = useState<boolean>(false);
    const { notifications, notificationResponse, notificationImages, readNotifications } = useSelector(
        (state: RootState) => state.notifications,
    );
    const [modalContent, setModalContent] = useState<Notification | null>(null);

    const getParams = ({ pageNumber }: { pageNumber: number }) => {
        return {
            pageNumber,
            pageSize: PAGE_SIZE,
            sortField: 'publicationDate',
            sortOrder: 'desc',
        } as NotificationArgs['pageable'];
    };

    const getNotifications = async () => {
        if (isAuthorized) {
            dispatch(getUnreadNotificationsCountThunk());
        }

        try {
            setMainLoader(true);
            await dispatch(
                getNotificationsThunk({
                    pageable: {
                        ...getParams({ pageNumber: 0 }),
                    },
                }),
            );
        } catch (error) {
            console.log(error);
        } finally {
            setMainLoader(false);
        }
    };

    const onHideOverlay = async () => {
        try {
            if (!isAuthorized) {
                const readNotificationsFromLocalStorage = getReadNotificationFromLocalStorage();
                const result = getUniqIds([...readNotificationsFromLocalStorage, ...readNotifications]);

                localStorage.setItem('read_notifications', JSON.stringify(result));
            }

            if (isAuthorized && readNotifications.length) {
                setMainLoader(true);
                await dispatch(
                    setNotificationStatusThunk({
                        notificationIds: readNotifications,
                    }),
                );
            }

            await getNotifications();
        } catch (error) {
            console.log(error);
        }

        setIsNotificationsOpen(false);
    };

    const onCardClick = (data: Notification) => {
        setModalContent(data);
    };

    const getExtraNotifications = async () => {
        try {
            setLoading(true);
            await dispatch(
                getExtraNotificationsThunk({
                    pageable: {
                        ...getParams({ pageNumber: notificationResponse!.pageNumber + 1 }),
                    },
                }),
            );
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const isRequestAllow =
            notificationResponse && notificationResponse.pageNumber + 1 <= notificationResponse.totalPages - 1;

        const handleScroll = async (e: any) => {
            const { scrollTop, scrollHeight, clientHeight } = e.target;

            setStartGettingExtraNotifications(scrollTop + clientHeight >= scrollHeight - 20);
        };

        if (contentRef && contentRef.current && isRequestAllow && isOpen && !loading) {
            contentRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            (!isOpen || loading) &&
                !isRequestAllow &&
                contentRef &&
                contentRef.current &&
                contentRef.current.removeEventListener('scroll', handleScroll);
        };
    }, [notificationResponse, isOpen, loading]);

    useEffect(() => {
        if (startGettingExtraNotifications) {
            (async () => await getExtraNotifications())();
        }
    }, [startGettingExtraNotifications]);

    const content = notifications?.length
? (
        <div>
            {notifications?.map((notification) => (
                <NotificationCard
                    key={notification.id}
                    notificationImages={notificationImages}
                    onCardClick={onCardClick}
                    notification={notification}
                    setIsVisible={setOpenNotificationModal}
                    readNotifications={readNotifications}
                />
            ))}
        </div>
    )
: (
        <EmptyNotifications />
    );

    return (
        <Sidebar
            pt={{
                root: (options) => ({
                    className: styles.notificationWrapper,
                }),
                mask: () => ({
                    className: styles.maskWrapper,
                }),
            }}
            visible={isOpen}
            position="right"
            onHide={onHideOverlay}
            onShow={getNotifications}
            showCloseIcon={false}
        >
            <div ref={contentRef} className={styles.contentWrapper}>
                {mainLoader
? (
                    <div className={styles.loaderWrapper}>
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                    </div>
                )
: (
                    content
                )}
            </div>

            {modalContent && (
                <NotificationModal
                    content={modalContent}
                    isVisible={openNotificationModal}
                    setIsVisible={setOpenNotificationModal}
                    notificationImages={notificationImages}
                />
            )}
        </Sidebar>
    );
};
