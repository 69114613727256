import { MapsContainer } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer';
import { FiltersContainer } from '@modules/PriceCenterProduct/InteractiveMap/FilterContainer';
import { type PriceCentrePermissions } from '@libs/types/subscription.type';

import styles from './styles.module.scss';

type InteractiveMapProps = {
    permissions: PriceCentrePermissions[];
};

export const InteractiveMap = ({ permissions }: InteractiveMapProps) => {
    return (
        <div className={styles.interactiveContainer}>
            <MapsContainer permissions={permissions} />
            <FiltersContainer permissions={permissions} />
        </div>
    );
};
