import { ModalWindow } from '@libs/components';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { getProductByGuidThunk } from '@store/store/thunk/product.thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { Select } from '@modules/CartPage/components/ProductsList/components/ProductItem/components';
import {
    CardItemTemplate,
    type PaymentItem,
} from '@modules/CartPage/components/ProductsList/components/Payment/CardItemTemplate';
import { getSavedCardsThunk } from '@store/store/thunk/card.thunk';
import { Button } from 'primereact/button';
import { createOrderThunk } from '@store/store/thunk/order.thunk';
import { useNavigate } from 'react-router-dom';
import { downloadFileThunk } from '@store/store/thunk/attachment.thunk';
import { Dropdown } from 'primereact/dropdown';
import {
    type NewCardData,
    type OrderReq,
    type SavedCard,
    type TariffCardType,
    type TariffFilterItem,
    type TariffPlanItem,
} from '@libs/types';
import { getCostThunk } from '@store/store/thunk/tariff.thunk';
import { useConfig } from '@dynamic-env/useConfig';
import { RenewalCheckbox } from '@libs/components/misc/RenewalCheckbox';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';

import styles from './styles.module.scss';
import { additionalPaymentMethods, limitIds, paymentSystems } from './data';

type Props = {
    closeModal: () => void;
    isVisible: boolean;
    productGuid: string;
    selectedPlan: TariffCardType;
    tariffPeriodList: TariffFilterItem[];
    selectedTariffPeriod?: TariffFilterItem;
};

export const PaymentModal = ({
    closeModal,
    isVisible,
    productGuid,
    selectedPlan,
    tariffPeriodList,
    selectedTariffPeriod,
}: Props) => {
    const { config } = useConfig();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const savedCards = useSelector((state: RootState) => state.card.savedCards);
    const paymentOptions = [...(savedCards || []), ...additionalPaymentMethods];
    const datashopTariffs = useSelector((state: RootState) => state.tariff.datashopTariffs);

    const [productId, setProductId] = useState<number>(0);
    const [logo, setLogo] = useState<string>();

    const [discountCost, setDiscountCost] = useState<number>(0);
    const [vat, setVat] = useState<number>(0);
    const [finalCost, setFinalCost] = useState<number>(0);
    const [selectedPayment, setSelectedPayment] = useState<PaymentItem | SavedCard>(paymentOptions[0]);

    const [selectedPeriod, setSelectedPeriod] = useState<TariffFilterItem | undefined>(selectedTariffPeriod);
    const [tariffPlanList, setTariffPlanList] = useState<TariffPlanItem[]>([]);
    const [selectedTariff, setSelectedTariff] = useState<TariffPlanItem>();
    const [selectedTariffPlan, setSelectedTariffPlan] = useState<TariffCardType | undefined>(selectedPlan);
    const [renewal, setRenewal] = useState<boolean>(true);

    useEffect(() => {
        dispatch(getProductByGuidThunk(productGuid)).then((resp) => {
            const result = unwrapResult(resp);

            if (result) {
                setProductId(result.id);
                dispatch(downloadFileThunk({ bucket: result.cartImage.bucket, ticket: result.cartImage.fileId }))
                    .then((resp) => {
                        const result = unwrapResult(resp);
                        setLogo(result);
                    })
                    .catch((e) => console.error(e));
            }
        });
        dispatch(getSavedCardsThunk());
    }, []);

    const getCost = (tariffPlan: TariffCardType | undefined) => {
        if (tariffPlan && tariffPlan.tariffParams?.length) {
            const body = {
                tariffs: [
                    {
                        tariffGuidOrMnemocode: tariffPlan.tariffGuid,
                        params: [
                            {
                                guid: tariffPlan.tariffParams[0].paramGuid,
                                selectedValue: tariffPlan.tariffParams[0].valueGuid,
                            },
                        ],
                    },
                ],
            };
            dispatch(getCostThunk(body)).then((res) => {
                const result = unwrapResult(res);

                if (result.length) {
                    setDiscountCost(result[0].discountCost || 0);
                    setVat(result[0].vat || 0);
                    setFinalCost(result[0].finalCost || 0);
                }
            });
        }
    };

    const getTariffPlan = () => {
        const resultList = datashopTariffs
            .map((item) => ({ title: item.name || '', guid: item.guid || '' }))
            .filter((item) => item.title && item.guid);
        setTariffPlanList(resultList);
        setSelectedTariff(resultList.find((item) => item.guid === selectedPlan?.tariffGuid));
    };

    useEffect(() => {
        setSelectedPayment(paymentOptions.find((item) => (item as SavedCard).isDefault) || paymentOptions[0]);
    }, [savedCards]);

    useEffect(() => {
        if (!isVisible) {
            setSelectedPayment(paymentOptions[0]);
        } else {
            setSelectedPeriod(selectedTariffPeriod);
            getTariffPlan();
        }
    }, [isVisible]);

    useEffect(() => {
        const findByTariff = datashopTariffs.find(
            (datashopTariff) => datashopTariff.guid === selectedTariff?.guid &&
                datashopTariff.billingTables?.some((item) => item.billingType === 'TC_BT_SUBSCRIPTION_BASED_DATA'),
        );

        if (findByTariff) {
            const tariffInfo = findByTariff?.billingTables?.find(
                (item) => item.billingType === 'TC_BT_SUBSCRIPTION_BASED_DATA',
            )!;

            const periodInfo = tariffInfo.tariffParams?.find((item) => item.values?.find((value) => value.value === selectedPeriod?.title),
            );

            if (periodInfo) {
                const valueGuid = periodInfo.values?.find((value) => value.value === selectedPeriod?.title)?.guid;

                const result = tariffInfo.tariffPlans?.find((item) => item.tariffParams?.find((param) => param.valueGuid === valueGuid),
                );

                if (result) {
                    const newTariff = {
                        vat: findByTariff.vat?.percent || 0,
                        title: selectedTariff?.title || '',
                        tariffGuid: selectedTariff?.guid || '',
                        ...result,
                    };
                    setSelectedTariffPlan(newTariff);
                    getCost(newTariff);
                }
            }
        }
    }, [selectedTariff, selectedPeriod]);

    const paymentHandler = () => {
        const body: OrderReq = {
            paymentMethod: !finalCost
                ? 'FREE'
                : paymentSystems.includes(selectedPayment.paymentSystem)
                  ? 'SAVED_CARD'
                  : selectedPayment.paymentSystem || 'NEW_CARD',
        };

        if (paymentSystems.includes(selectedPayment.paymentSystem) && 'id' in selectedPayment && finalCost) {
            body.cardId = selectedPayment.id;
        }

        if (selectedTariffPlan) {
            body.product = {
                id: productId,
                productId: productGuid,
                tariffId: selectedTariffPlan.tariffGuid,
                renewal,
                tariffParams:
                    selectedTariffPlan.tariffParams?.map((item) => ({
                        paramId: item.paramGuid,
                        valueId: item.valueGuid,
                    })) || [],
            };
        }

        dispatch(showLoader());
        dispatch(createOrderThunk(body))
            .then((resp) => {
                dispatch(hideLoader());
                const result = unwrapResult(resp);

                if (result) {
                    if (['NEW_CARD', 'QR'].includes(result.paymentMethod)) {
                        const { accountId, operationId, amount, transactionId, signature } = result.data as NewCardData;
                        window.location.href = `${config.REACT_APP_MONETA_API}?MNT_ID=${accountId}&operationId=${operationId}&MNT_AMOUNT=${amount}&MNT_CURRENCY_CODE=RUB&MNT_TRANSACTION_ID=${transactionId}&MNT_SIGNATURE=${signature}&paymentSystem.limitIds=${limitIds[result.paymentMethod]}&MNT_SUCCESS_URL=${process.env.REACT_APP_DS_URL}/payment-info/success&MNT_FAIL_URL=${process.env.REACT_APP_DS_URL}/payment-info/error`;
                    } else if (result.paymentMethod === 'SAVED_CARD') {
                        const { transactionId } = result.data;
                        navigate(`/payment-info/success?MNT_TRANSACTION_ID=${transactionId}`);
                    } else if (result.paymentMethod === 'FREE') {
                        navigate('/personal-account');
                    }
                }
            })
            .catch(() => {
                dispatch(hideLoader());
                navigate('/payment-info/error');
            });
    };

    return (
        <ModalWindow closeModal={closeModal} isVisible={isVisible} className={styles.paymentModal}>
            <div className={classNames(styles.titleWrapper, 'flex', 'flex-column')}>
                <h4>Ваш заказ</h4>
                <div style={{ background: `url(${logo}) no-repeat`, backgroundSize: 'contain' }} />
            </div>

            <div className={classNames(styles.tariffsWrapper, 'flex', 'flex-column')}>
                <div className={classNames(styles.dropdownWrapper, 'flex', 'flex-column')}>
                    <span>{'Тариф'}</span>
                    <Dropdown
                        options={tariffPlanList}
                        optionValue="guid"
                        optionLabel="title"
                        value={selectedTariff?.guid}
                        panelClassName={styles.panelStyle}
                        onChange={(e) => {
                            setSelectedTariff(tariffPlanList.find((item) => item.guid === e.value));
                        }}
                        className={styles.dropdownFilter}
                    />
                </div>

                <div className={classNames(styles.dropdownWrapper, 'flex', 'flex-column')}>
                    <span>{'Период оплаты'}</span>
                    <Dropdown
                        options={tariffPeriodList}
                        optionValue="code"
                        optionLabel="title"
                        value={selectedPeriod?.code}
                        panelClassName={styles.panelStyle}
                        onChange={(e) => {
                            setSelectedPeriod(tariffPeriodList.find((item) => item.code === e.value));
                        }}
                        className={styles.dropdownFilter}
                    />
                </div>
            </div>

            <div className={styles.renewalWrapper}>
                <RenewalCheckbox value={renewal} setValue={setRenewal} />
            </div>

            <div className={classNames(styles.totalSumWrapper, 'flex', 'flex-column')}>
                <div className={classNames(styles.sumItem, 'flex', 'justify-content-between')}>
                    <p>Общая сумма</p>
                    <p>
                        {discountCost}
                        <span>₽</span>
                    </p>
                </div>
                <div className={classNames(styles.sumItem, 'flex', 'justify-content-between')}>
                    <p>НДС</p>
                    <p>
                        {vat}
                        <span>₽</span>
                    </p>
                </div>
                <div className={classNames(styles.sumItem, styles.totalItem, 'flex', 'justify-content-between')}>
                    <p>Итого к оплате</p>
                    <p>
                        {finalCost}
                        <span>₽</span>
                    </p>
                </div>
            </div>

            <div className={classNames(styles.paymentMethodWrapper, !finalCost && styles.free, 'flex', 'flex-column')}>
                {!!finalCost && (
                    <>
                        <h6>Способ оплаты</h6>
                        <Select
                            options={paymentOptions}
                            value={selectedPayment}
                            valueTemplate={CardItemTemplate}
                            itemTemplate={CardItemTemplate}
                            onChangeHandler={setSelectedPayment}
                            optionLabel={'cardNumber'}
                        />
                    </>
                )}
                <Button label={!finalCost ? 'Получить доступ' : 'Оплатить'} onClick={paymentHandler} />
            </div>
        </ModalWindow>
    );
};
